.modal {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0px;
  left: 0px;
  background: rgba(0, 0, 0, 0.4);
  display: flex;
  z-index: 2000;
  padding: 2rem calc(4rem + 15px) 2rem 4rem;
  margin: 0 10px 10px 0;
}

@media (max-width: 40rem) {
  .modal {
    padding: 1rem calc(1rem + 15px) 1rem 1rem;
  }
}

.modalInterno {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 60px;
  left: 0px;
  display: flex;
  z-index: 5000;
  padding: 2rem calc(4rem + 15px) 2rem 4rem;
  margin: 0 10px 10px 0;
}

@media (max-width: 40rem) {
  .modalInterno {
    padding: 1rem calc(1rem + 15px) 1rem 1rem;
  }
}
