.footer {
  background: rgb(0 102 162);
  background-color: rgb(9 11 12);
  text-align: center;
  color: #fff;
  padding: 20px 0;
}

.footer p {
  margin-top: 1rem;
}

.banner {
  background-color: rgb(52 58 64);
  text-align: center;
  color: #fff;
  padding: 10px 0;
}

.banner a:link {
  color: #fff;
}

.banner a:visited {
  color: #fff;
}

.banner a:active {
  color: #fff;
}

.banner a:hover {
  color: #ff0;
}
