.wrapper {
  margin: 1rem auto;
  width: 100%;
  position: relative;
}

.wrapper .input {
  border: 1px solid #eee;
  display: block;
  width: 100%;
  font-size: 1rem;
  padding: 0.8rem;
  border-radius: 0.9rem;
  background: #eee;
  transition: 0.2s;

  padding: 10px;
  font-size: 17px;
  border: 1px solid grey;
  background: #f1f1f1;
  z-index: 10;  
}

/* Style the submit button */
.wrapper button {
  position: absolute;
  right: 5px;
  top: 4px;
  padding: 0 10px;
  color: #000;
  border: none;
  border-radius: 50%;
  height: 40px;
  font-size: 17px;
  cursor: pointer;
  background: none;
  z-index: 100;
}

.wrapper button:hover {
  color: #f2f2f2;
}


.input:focus,
.input:hover {
  outline: none;
  border-color: #39c643;
  background: #f2f2f2;
  box-shadow: 0 0 0 3px #fea;
}

.label {
  display: block;
  font-size: 1rem;
  line-height: 1;
  padding-bottom: 0.5rem;
}

.error {
  color: #f31;
  font-size: 0.875rem;
  margin-top: 0.25rem;
}
