.modal {
  position: fixed;
  width: 90vw;
  height: 90vh;
  background: #fff;
  display: flex;
  border-radius: 10px;
  padding: 2rem calc(4rem + 15px) 2rem 4rem;
  margin: 50px 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow-y: scroll;
}

@media (max-width: 40rem) {
  .modal {
    padding: 2rem calc(2rem + 15px) 2rem 2rem;
  }
}


.outside {
  background-color: rgba(0, 0, 0, 0.4);
  position: fixed;
  width: 100%;
  height: 100%;
  display: block;
  margin-top: -200px;
}

@media (max-width: 40rem) {
  .outside {
    display: block;
  }
}