.list {
  padding: 0px;
  margin: 0px;
  list-style: none;
  position: relative;
}

.item {
  display: grid;
  grid-template-columns: auto 1fr auto auto auto auto;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  background: #f9f9f9;
  border-radius: 4px;
  margin-bottom: 1rem;
  padding-right: 1rem;
}

.img {
  max-height: 100px;
  border-radius: 4px;
  width: auto;
}

.title {
  font-size: 1rem;
  font-weight: normal;
  margin: 0px;
}

.acessos {
  font-family: monospace;
  font-size: 0.875rem;
  color: #999;
}

.modalitem {
  height: 200px;
  width: 200px;
  padding: 2em;
  position: absolute;
  left: calc(50% - 100px);
  top: calc(50% - 200px);
  background-color: #FFF;
  z-index: 10;
  border-radius: 10px;
  transition: all 0.3s;
  box-sizing: border-box;
  border: 1px solid #39c643;
}

.fechar {
  height: 10px;
  width: 10px;
  padding: 10px;
  position: absolute;
  right: 0;
  top: 0;
  border-radius: 10px;
  box-sizing: border-box;
  color: #000;
  display: inline-table;
  cursor: pointer;
  z-index: 15;
}


.modal {
  position: fixed;
  width: 90vw;
  height: 90vh;
  background: #fff;
  display: flex;
  border-radius: 10px;
  padding: 2rem calc(4rem + 15px) 2rem 4rem;
  margin: 50px 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow-y: scroll;
}

@media (max-width: 40rem) {
  .modal {
    padding: 2rem calc(2rem + 15px) 2rem 2rem;
  }
}


.outside {
  background-color: rgba(0, 0, 0, 0.4);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: block;
  z-index: 100;
}

@media (max-width: 40rem) {
  .outside {
    display: block;
  }
}