.photoPost {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem;
  margin-bottom: 2rem;
}

@media (max-width: 992px) {
  .photoPost {
    grid-template-columns: 1fr;
  }
}

.file {
  margin-bottom: 1rem;
}

.preview {
  border-radius: 1rem;
  background-size: cover;
  background-position: center center;
}

.preview::after {
  content: '';
  display: block;
  height: 0px;
  padding-bottom: 100%;
}

.modalk {
  position: fixed;
  width: 90vw;
  height: 90vh;
  background: #fff;
  display: flex;
  border-radius: 10px;
  padding: 2rem calc(4rem + 15px) 2rem 4rem;
  margin: 50px 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow-y: scroll;
}

@media (max-width: 40rem) {
  .modalk {
    padding: 2rem calc(2rem + 15px) 2rem 2rem;
  }
}


.modalx {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0px;
  left: 0px;
  background: rgba(0, 0, 0, 0.4);
  display: flex;
  z-index: 2000;
  padding: 2rem calc(4rem + 15px) 2rem 4rem;
  margin: 0 10px 10px 0;
}

@media (max-width: 40rem) {
  .modalx {
    padding: 2rem calc(2rem + 15px) 2rem 2rem;
  }
}


