.perfil {
  background: rgb(249 249 249);
  padding: 10px;
  margin: 0 10px 10px 0;
  text-align: center;
}

.outside {
  display: flex;
  overflow: hidden;
  border-radius: 10px;
}

@media (max-width: 40rem) {
  .outside {
    display: block;
  }
}
