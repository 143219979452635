.label,
.input,
.button {
  font-family: Roboto, sans-serif;
  font-size: 1rem;
}

.label {
  display: block;
  margin-bottom: 0.25rem;
}

.input {
  border: 1px solid;
  border-radius: 3px;
  width: 100%;
  padding: 0.5rem;
  box-sizing: border-box;
  margin-bottom: 0.5rem;
  display: block;
}

.button {
  color: white;
  background: black;
  border: none;
  padding: 0.7rem 2rem;
  margin-top: 0.5rem;
  border-radius: 3px;
  cursor: pointer;
}
