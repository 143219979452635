.header {
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: 2rem;
}

.title {
  font-size: 1rem;
  margin: 0px;
}

.login {
  cursor: pointer;
  padding: 0px;
  margin: 0px;
  display: block;
  background: white;
  width: 8px;
  height: 8px;
  border: 2px solid;
  box-sizing: border-box;
  border-radius: 50%;
}

.login.loading {
  background: orange;
}

.login.loaded {
  background: greenyellow;
}
