.feed {
  display: grid;
  grid-gap: 10px;
  grid-template-columns: repeat(4, 1fr);
  margin-bottom: 1rem;
  justify-items: center;
}

@media (max-width: 80rem) {
  .feed {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (max-width: 60rem) {
  .feed {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 50rem) {
  .feed {
    grid-template-columns: repeat(1, 1fr);
  }
}
