.photoPost {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem;
  margin-bottom: 2rem;
}

@media (max-width: 40rem) {
  .photoPost {
    grid-template-columns: 1fr;
  }
}

.file {
  margin-bottom: 1rem;
}

.preview {
  border-radius: 1rem;
  background-size: cover;
  background-position: center center;
}

.preview::after {
  content: '';
  display: block;
  height: 0px;
  padding-bottom: 100%;
}

.modal {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0px;
  left: 0px;
  background: rgba(0, 0, 0, 0.4);
  display: flex;
  z-index: 2000;
  padding: 2rem calc(4rem + 15px) 2rem 4rem;
  margin: 0 10px 10px 0;
}

@media (max-width: 40rem) {
  .modal {
    padding: 2rem calc(2rem + 15px) 2rem 2rem;
  }
}
